import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Content.css';
import './Sidebar.css';
import LoginForm from './LoginForm';
import RightSidebar from './RightSidebar';
import Sidebar from './Sidebar';
import LinearWithValueLabel from './LinearWithValueLabel';
import { Modal } from '@mui/material';

const Content = ({ loggedIn, setLoggedIn, setCurrentUser, user }) => {
  const [keyword, setKeyword] = useState('');
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [articles, setArticles] = useState([]);
  const [progress, setProgress] = useState(0);
  const [loadingMessage, setLoadingMessage] = useState('');

  useEffect(() => {
    if (loggedIn) {
      axios.get(`${process.env.REACT_APP_API_URL}/api/v1/user`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('sessionToken')}`
        }
      })
      .then(response => {
        setCurrentUser(response.data.user);
      })
      .catch(error => {
        console.error('Failed to fetch user details', error);
        setErrorMessage('ユーザー情報の取得に失敗しました。');
      });
    }
  }, [loggedIn, setCurrentUser]);

  useEffect(() => {
    if (progress >= 0 && progress < 40) {
      setLoadingMessage('AIが競合サイトを解析しています...');
    } else if (progress >= 40 && progress < 80) {
      setLoadingMessage('AIが競合サイトの見出しを解析しています...');
    } else if (progress >= 80 && progress <= 100) {
      setLoadingMessage('文章を生成しています...');
    }
  }, [progress]);

  const handleInputChange = (e) => {
    setKeyword(e.target.value);
  };

  const handleButtonClick = async () => {
    if (keyword.trim() === '') {
      setErrorMessage('キーワードを入力してください。');
      return;
    }

    setLoading(true);
    setErrorMessage('');
    setProgress(0);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/articles`, {
        article: {
          keyword: keyword,
          prompts: {
            generateTableOfContents: `${keyword}に関するやや網羅的な目次を1,1-1のような形式で作成して。`,
            mainContent: `${keyword}に関するブログ記事の本文を作成して。`
          }
        }
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('sessionToken')}`
        },
        onUploadProgress: progressEvent => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setProgress(percentCompleted);
        },
        onDownloadProgress: progressEvent => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setProgress(percentCompleted);
        }
      });

      if (response.status === 201) {
        const articleResponse = response.data;
        console.log("API Response:", articleResponse);

        const tableOfContents = articleResponse.tableOfContents.split('\n').filter(item => item.trim() !== '');
        const sections = tableOfContents.map((toc, index) => ({
          title: toc,
          content: `ここに${toc}に関する内容が表示されます。`
        }));

        setResults([{
          title: "生成された記事",
          thumbnail: articleResponse.thumbnail,
          tableOfContents: tableOfContents,
          sections: sections,
          content: articleResponse.article || ''
        }]);

        setArticles(articleResponse.results);
      } else {
        setErrorMessage('記事の生成に失敗しました。');
      }
    } catch (error) {
      console.error('API error:', error);
      setErrorMessage('記事の生成に失敗しました。');
    } finally {
      setLoading(false);
    }
  };

  const handleGenerateArticleClick = () => {
    if (!loggedIn) {
      setShowModal(true);
    } else {
      handleButtonClick();
    }
  };

  const handleLoginSuccess = () => {
    setShowModal(false);
  };

  return (
    <div className="container">
      <Sidebar
        totalCharacters={results.reduce((sum, result) => sum + (result.content.length || 0), 0)}
        totalCost={0}
        handleGenerateClick={handleGenerateArticleClick}
        handleSaveClick={() => {}}
        handleIncreaseCharacters={() => {}}
        articles={articles}
        loggedIn={loggedIn}
        user={user}
      />
      <div className="content">
        <input
          type="text"
          placeholder="作成したい記事のキーワード"
          value={keyword}
          onChange={handleInputChange}
        />
        <button className="sidebar-button generate" onClick={handleGenerateArticleClick}>記事作成をする</button>
        {loading && (
          <>
            <LinearWithValueLabel progress={progress} />
            <p>{loadingMessage}</p>
          </>
        )}
        {errorMessage && <div>{errorMessage}</div>}
        <div className={`generated-article ${loggedIn ? 'logged-in' : ''}`} onClick={handleGenerateArticleClick}>
          {results.map((result, index) => (
            <article key={index} className="article-container">
              <div className="article-header">
                {result.thumbnail && (
                  <img src={result.thumbnail} alt="サムネイル画像" className="article-thumbnail" />
                )}
                <h1>{result.title}</h1>
                <p>著者: <span>AI生成</span></p>
                <p>更新日: <span>{new Date().toLocaleDateString()}</span></p>
              </div>
              <nav className="table-of-contents">
                <h2>目次</h2>
                <ul>
                  {result.tableOfContents && result.tableOfContents.length > 0 ? (
                    result.tableOfContents.map((toc, tocIndex) => (
                      <li key={tocIndex}><a href={`#section${tocIndex}-${index}`}>{toc}</a></li>
                    ))
                  ) : (
                    <li>目次がありません</li>
                  )}
                </ul>
              </nav>
              <div className="article-body">
                <p className={`generate-article ${loggedIn ? 'blur-none' : ''}`}>
                  {result.content.split('\n').map((text, idx) => (
                    <React.Fragment key={idx}>
                      {text}
                      <br />
                    </React.Fragment>
                  ))}
                </p>
                {result.sections && result.sections.map((section, sectionIndex) => (
                  <section key={sectionIndex} id={`section${sectionIndex}-${index}`} className="content-section">
                    <h2>{section.title}</h2>
                    <p className={`generate-article ${loggedIn ? 'blur-none' : ''}`}>
                      {section.content.split('\n').map((text, idx) => (
                        <React.Fragment key={idx}>
                          {text}
                          <br />
                        </React.Fragment>
                      ))}
                    </p>
                  </section>
                ))}
              </div>
            </article>
          ))}
        </div>
      </div>
      <RightSidebar />
      <Modal open={showModal} onClose={() => setShowModal(false)}>
        <div className="modal-content">
          <h2>ログイン</h2>
          <LoginForm setLoggedIn={setLoggedIn} setCurrentUser={setCurrentUser} onLoginSuccess={handleLoginSuccess} />
        </div>
      </Modal>
    </div>
  );
};

export default Content;
