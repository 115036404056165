import React, { useState } from 'react';
import axios from 'axios';

const signupUrl = `https://ai-writting-maker-api.fly.dev/api/v1/signup`;

const SignupForm = ({ setLoggedIn }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [bio, setBio] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(signupUrl, {
        user: {
          email: email,
          password: password,
          password_confirmation: password,
          profile_attributes: {
            bio: bio
          }
        }
      });
      if (response.status === 200) {
        setLoggedIn(true);
      }
    } catch (error) {
      console.error('Signup error:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>Email:</label>
        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
      </div>
      <div>
        <label>Password:</label>
        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
      </div>
      {/* <div>
        <label>Bio:</label>
        <input type="text" value={bio} onChange={(e) => setBio(e.target.value)} />
      </div> */}
      <button type="submit">Signup</button>
    </form>
  );
};

export default SignupForm;
