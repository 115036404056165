import React, { useEffect, useState } from 'react';
import { Avatar, Menu, MenuItem, IconButton, Typography } from '@mui/material';
import './css/RightSidebar.css';

const RightSidebar = ({ user, handleLogout }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const isLoggedIn = Boolean(user?.email);

  useEffect(() => {
    console.log('isLoggedIndayooyoyoy:', isLoggedIn);
  }, [isLoggedIn]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogoutClick = () => {
    handleLogout();
    handleClose();
  };

  return (
    <div className="right-sidebar">
      <Typography variant="body1" className="login-status">
        {isLoggedIn ? `ログイン中: ${user.email}` : '未ログイン'}
      </Typography>

      <IconButton
        onClick={handleClick}
        aria-controls={open ? 'profile-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        <Avatar alt={user?.name || 'User'} src={user?.avatar || '/default-avatar.png'} />
      </IconButton>
      <Menu
        id="profile-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={handleClose}>Profile</MenuItem>
        <MenuItem onClick={handleClose}>My account</MenuItem>
        <MenuItem onClick={handleLogoutClick}>Logout</MenuItem>
      </Menu>

      <div className="sidebar-icon">
        <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" color="#333333" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" style={{ color: 'rgb(51, 51, 51)' }}>
          <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
          <polyline points="7 10 12 15 17 10"></polyline>
          <line x1="12" y1="15" x2="12" y2="3"></line>
        </svg>
      </div>

      <div className="sidebar-icon">
        <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
          <circle cx="18" cy="5" r="3"></circle>
          <circle cx="6" cy="12" r="3"></circle>
          <circle cx="18" cy="19" r="3"></circle>
          <line x1="8.59" y1="13.51" x2="15.42" y2="17.49"></line>
          <line x1="15.41" y1="6.51" x2="8.59" y2="10.49"></line>
        </svg>
      </div>

      <div className="sidebar-icon">
        <img width="20" height="20" src="https://img.icons8.com/glyph-neue/200/444444/wordpress.png" alt="WordPress" />
      </div>
    </div>
  );
};

export default RightSidebar;
