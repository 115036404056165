
import React from 'react';
import axios from 'axios';

const LogoutButton = ({ setLoggedIn }) => {
  const handleLogout = async () => {
    try {
      await axios.delete('/api/v1/logout');
      setLoggedIn(false);
    } catch (error) {
      console.error('Logout error:', error);
    }
  };
  
  // return <button onClick={handleLogout}>Logout</button>;
};

export default LogoutButton;
