import React, { useState } from 'react';
import axios from 'axios';
import SignupForm from './SignupForm';
import './LoginForm.css';

// const loginUrl = "http://127.0.0.1:3000/api/v1/login"
const loginUrl = 
  process.env.NODE_ENV === 'production'
    ? 'https://ai-writting-maker-api.fly.dev/api/v1/login'
    : 'https://ai-writting-maker-api.fly.dev/api/v1/login';

const LoginForm = ({ setLoggedIn, setCurrentUser }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showSignup, setShowSignup] = useState(false);

  const handleLogin = async () => {
    try {
      const response = await axios.post(
        loginUrl,
        {
          user: { email, password }
        },
        {
          withCredentials: true
        }
      );

      if (response.status === 200) {
        const { user, token } = response.data;

        console.log('Token:', token); // トークンを確認
        localStorage.setItem('sessionToken', token);
        console.log('Token saved:', localStorage.getItem('sessionToken'));

        setLoggedIn(user, token);
        setCurrentUser(user);
      }
    } catch (error) {
      console.error('Login error:', error);
    }
  };

  return (
    <div className="login-form">
      <input
        type="email"
        placeholder="メールアドレス"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <input
        type="password"
        placeholder="パスワード"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <div className="links">
        <button onClick={() => setShowSignup(true)}>新規登録はこちら</button>
        <a href="/reset-password">パスワード再設定</a>
      </div>
      <button onClick={handleLogin}>ログインする</button>

      {showSignup && (
        <div className="modal">
          <div className="modal-content">
            <button onClick={() => setShowSignup(false)}>閉じる</button>
            <SignupForm />
          </div>
        </div>
      )}
    </div>
  );
};

export default LoginForm;
