import React from 'react';
import './Sidebar.css';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const Sidebar = ({ totalCharacters, totalCost, handleGenerateClick, handleSaveClick, handleIncreaseCharacters, articles, loggedIn, user }) => {
  return (
    <div className="sidebar">
      <button className="sidebar-button generate" onClick={handleGenerateClick}>記事を再生成</button>
      <button className="sidebar-button save" onClick={handleSaveClick}>記事を保存</button>
      <button className="sidebar-button increase" onClick={handleIncreaseCharacters}>文字数を増やす</button>
      <p className="total-characters">合計文字数：{totalCharacters}文字</p>
      
      {loggedIn && user && (  // loggedIn と user が両方とも存在することを確認
        <div className="user-info">
          <AccountCircleIcon style={{ fontSize: 40 }} />
          <p>{user.email}</p>
          <p>契約ステータス: {user.plan === 'paid' ? '有料プラン' : 'フリープラン'}</p>
        </div>
      )}
      
      <div className="article-list">
        <h3>調査した競合サイト一覧</h3>
        <ul>
          {articles.map((article, index) => (
            <li key={index}>
              <a href={article.link} target="_blank" rel="noopener noreferrer">
                {article.thumbnail && (
                  <img src={article.thumbnail} alt={article.title} />
                )}
                <h4>{article.title}</h4>
                <p>{article.description}</p>
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
