import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Sidebar from './Sidebar';
import Content from './Content';
import LoginForm from './LoginForm';
import SignupForm from './SignupForm';
import LogoutButton from './LogoutButton';
import RightSidebar from './RightSidebar';
import './App.css';
import { Modal } from '@mui/material';

// Axiosの設定: クレデンシャルを含めてリクエストを送信
axios.defaults.withCredentials = true;

console.log("API URL from env:", process.env.REACT_APP_API_URL);

const loginUrl = `${process.env.REACT_APP_API_URL}/api/v1/login`;
const articlesUrl = `${process.env.REACT_APP_API_URL}/api/v1/articles`;

console.log("Login URL:", loginUrl);
console.log("Articles URL:", articlesUrl);

const App = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [results, setResults] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [articleKeyword, setArticleKeyword] = useState('');
  const [tokenCount, setTokenCount] = useState(0);
  const [articles, setArticles] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const sessionToken = localStorage.getItem('sessionToken');
    console.log('Loaded sessionToken:', sessionToken);
    
    if (sessionToken) {
      axios.get(`${process.env.REACT_APP_API_URL}/api/v1/sessions/current`, {
        headers: {
          Authorization: `Bearer ${sessionToken}`
        }
      })
      .then(response => {
        if (response.status === 200) {
          setLoggedIn(true);
          setCurrentUser(response.data.user);
          console.log('Session validated successfully');
        }
      })
      .catch(error => {
        console.error('Session validation error:', error);
        setLoggedIn(false);
        setCurrentUser(null);
      });
    }
  }, []);

  const handleLogin = (user, token) => {
    console.log('User logged in:', user);
    setLoggedIn(true);
    setCurrentUser(user);
    localStorage.setItem('sessionToken', token); // トークンを保存
    console.log('Saved sessionToken:', token);
  };
  
  const handleLogout = async () => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/logout`);
      localStorage.removeItem('sessionToken'); // トークンを削除
      console.log('Removed sessionToken');
      setLoggedIn(false);
      setCurrentUser(null);
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const handleGenerateArticle = async (keyword, prompts) => {
    setLoading(true);
    setProgress(0);

    try {
      const createResponse = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/articles`, {
        article: {
          keyword: keyword,
          prompts: prompts
        }
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('sessionToken')}` // トークンを送信
        }
      });

      if (createResponse.status === 201) {
        const articleResponse = createResponse.data.article;

        console.log("API Response:", articleResponse); // APIレスポンスのログを出力

        setResults([{
          title: articleResponse.title,
          thumbnail: articleResponse.thumbnail,
          tableOfContents: articleResponse.tableOfContents,
          sections: articleResponse.sections
        }]);

        setTokenCount(articleResponse.content.length);
      }
    } catch (error) {
      console.error("API error:", error);
      setErrorMessage('記事の生成に失敗しました。');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="app-container">
      <Modal open={showModal} onClose={() => setShowModal(false)}>
        <div className="modal-content">
          <h2>ログイン</h2>
          <LoginForm setLoggedIn={handleLogin} setCurrentUser={setCurrentUser} />
          <h2>会員登録</h2>
          <SignupForm setLoggedIn={handleLogin} setCurrentUser={setCurrentUser} />
        </div>
      </Modal>
      <LogoutButton setLoggedIn={handleLogout} />
      <div className="content-container">
        <Content
          results={results}
          setResults={setResults}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          tokenCount={tokenCount}
          setTokenCount={setTokenCount}
          handleGenerateArticle={handleGenerateArticle}
          loading={loading}
          setLoading={setLoading}
          progress={progress}
          setProgress={setProgress}
          loggedIn={loggedIn}
          setLoggedIn={handleLogin}
          setCurrentUser={setCurrentUser}
          user={currentUser}
        />
        <RightSidebar user={currentUser} handleLogout={handleLogout} />
      </div>
    </div>
  );
};

export default App;
